<template>
  <div>
    <div class="w-full h-full px-5 py-5">
      <loader v-if="isLoading" size="xxs" :loader-image="false" />
      <template v-else>
        <div class="flex flex-col gap-8">
          <div class="flex justify-start items-center gap-8">
            <h1 class="text-xl text-left font-extrabold">
              Document Management
            </h1>
            <breadcrumb :items="breadcrumbs" />
          </div>
          <div class="flex flex-col gap-0 my-3">
            <div class="shadow rounded">
              <tab
                :border="true"
                :tabs="tabs"
                :active-tab="currentTab"
                @currentTab="handleCurrentTab($event)"
              />
            </div>
            <folders />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Folders from './folders';

export default {
  name: "DocumentManagement",
  components: {
    Breadcrumb,
    Tab,
    Folders
  },
  computed: {
    breadcrumbs() {
      return [
        {
          disabled: false,
          text: "Document Management",
          href: "OrganisationCompanyDocuments",
          id: "DocumentMgt",
        },
        {
          disabled: false,
          text: "Organization Documents",
          href: "OrganisationDocuments",
          id: "OrgDocument",
        },
      ];
    },
  },
  data() {
    return {
      tabs: ["Folders", "Files"],
      currentTab: this.$route.query.currentTab || "Folders",
      isLoading: false
    };
  },
  methods: {
    handleCurrentTab(currentTab) {
      if (this.currentTab !== currentTab) {
        this.currentTab = currentTab;
        this.$router.push({ query: { currentTab } });
      }
    },
  },
};
</script>
