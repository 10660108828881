<template>
  <div>
  <div class="w-full h-full flex flex-col my-10">
    <div class="w-full flex py-6 px-12 gap-6" style="background-color: #f6f6f6">
      <div class="flex flex-grow">
        <div class="flex flex-col w-3/4 justify-center gap-2">
          <p class="text-xl font-bold">
            Securely store and share your organisation files.
          </p>
          <p class="text-sm">
            Upload and manage files that help you run your organisation here.
          </p>
        </div>
        <div class="flex w-1/4 justify-end">
          <div class="flex flex-col justify-center">
            <img
              src="@/assets/images/folder_open.png"
              style="width: 100px; height: 100px"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col gap-4 my-10">
      <p class="text-xs font-black text-romanSilver uppercase">
        Recently Added (7)
      </p>
      <carousel :item-width="averageWidth" :items-length="items.length">
        <template v-slot:carouselItems>
          <Card v-for="(item, index) in items" :key="index" class="carousel-item">
            <div class="flex p-4 gap-5" ref="itemContainer">
              <div class="flex flex-col justify-center">
                <icon icon-name="document" size="xsm" />
              </div>
              <div class="flex flex-col justify-center">
                <p class="text-sm font-semibold">{{ item }}</p>
                <p class="text-xs font-black text-romanSilver">
                  125 Folders, 258MB.
                </p>
              </div>
            </div>
          </Card>
        </template>
      </carousel>
    </div>
  </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel";
import Card from "@/components/Card";

export default {
  name: "Folders",
  components: {
    Carousel,
    Card,
  },
  data() {
    return {
      items: [
        "All Folders",
        "Employment Contracts",
        "Onboarding Procedure",
        "Recruitment Process",
        "Employment Contracts",
        "Onboarding Procedure",
      ],
      averageWidth: 0,
      isLoading: true
    };
  },

  mounted() {
    this.averageWidth = (this.$queryContainerLength(this.$refs.itemContainer) / this.items.length);
  },
};
</script>
